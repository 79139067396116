//@import '~antd/dist/antd.less';
@import 'antd/dist/antd.min.css';

/*Esempio import fonts */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*DEFINE MY VARIABLE*/
$my-color: #ccc;
$bg-color: #f8f8f8;
$primary-color: #264395;
$secondary-color: #F2F5FF;
$tertiary-color: #8B9CCE;
$quaternary-color: #D3D9EB;
$quinary-color: #ffffff;
$copy-color: #1B1B1B;
$back-form-color: #E8EEFF;

$bg-x: #E9EDF5;
$bg-xx: #181818;
$radius: 10px;

$font-family: 'Rubik';
$font-weight-1: 700;
$font-weight-2: 500;
$font-weight-3: 400;
$font-weight-4: 300;
$width-content: 1280px; //1304px;
$tablet-width: 1024px;
$max-width-mobile: 769px;
$content-max-width: 1920px;
$column-gap: 28px;

/*video::-webkit-media-controls-timeline {
    display: none;
}*/


.iselected path {
    fill: $primary-color;
}

.svg-ter path {
    fill: $tertiary-color;
}

.primary {
    color: $primary-color  !important;
}

.bg-sec {
    background-color: $secondary-color;
}

.ts-pri {
    background-color: white;
    color: $primary-color;
    width: fit-content;
    font-weight: $font-weight-2;
}

.ts-pri-inv {
    background-color: $primary-color;
    color: white;
    width: fit-content;
    font-weight: $font-weight-3;
}

.ts-ter-bg {
    background-color: $tertiary-color;
    color: white;
    width: fit-content;
    font-weight: $font-weight-3;
}

.img-resize {
    max-width: 100%;
    height: auto;

    @media (max-width: $max-width-mobile) {
        margin: auto;
        display: block;
    }

}

.img-scale {
    width: 100%;
    max-width: 480px;
    height: auto;

    @media (max-width: $max-width-mobile) {
        margin: auto;
        display: block;
    }
}

.bold {
    font-weight: $font-weight-2;
}

.bolder {
    font-weight: $font-weight-1;
}

.padding-sm {
    padding: 12px;
}

.padding-md {
    padding: 24px;
}

.padding-lg {
    padding: 36px;
}

.padding-xl {
    padding: 48px;
}

.py-md {
    padding: 24px 0;
}


.mb-md {
    margin-bottom: 24px;
}

.mb-sm {
    margin-bottom: 12px;
}

.mt-md {
    margin-top: 24px;
}

.pt-col {
    padding-top: 0px;

    @media (max-width: $max-width-mobile) {
        padding-top: 12px;
    }
}

.h-main {
    height: calc(100vh - 48px);
}

.h-sec {
    height: calc(100vh - 168px);
}

.col-max-width {
    max-width: 480px;
    padding-left: 0;

    @media (min-width: $max-width-mobile) {
        max-width: 100%;
        padding-left: 24px;
    }
}

.px-media {
    padding: 0;
    @media (max-width: $max-width-mobile) {
        padding: 0 36px !important;
    }
}

.p-media {
    padding: 24px;

    @media (max-width: $max-width-mobile) {
        padding: 16px 16px 16px 36px !important;
    }
}

.pl-media {
    padding-left: 24px;

    @media (max-width: $max-width-mobile) {
        padding-left: 0;
    }
}

.color-3 {
    color: $tertiary-color;
}

.square {
    width: 24px;
    height: 24px;
    padding-top: 4px;
    background-color: $quaternary-color;
    text-align: center;
}

.csquare {
    width: 24px;
    height: 24px;
    padding-top: 4px;
    background-color: $primary-color;
    color: white;
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.layout {
    height: 100%;
}

.w100 {
    width: 100%;
}

.mx-md {
    margin-right: 32px;
    margin-left: 32px;
}

.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

/*Giusto per prova -> Poi si può eliminare tutto*/
.slick-slide {
    width: 298px !important;
    margin-right: 28px !important;
}

.slick-cloned {
    margin-right: 0 !important;
}


.try-carousel-item {
    background-color: $primary-color;

    &-1 {
        background-image: url(./assets/img/area-commerciale.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 305px;
    }

    &-2 {
        background-image: url(./assets/img/area-tecnica.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 305px;
    }

    &-3 {
        background-image: url(./assets/img/area-tecnica.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 305px;
    }

    &-4 {
        background-image: url(./assets/img/area-tecnica.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 305px;
    }
}

/*Definire stile hx e oltre*/
body {
    font-family: $font-family;
    font-size: 16px;
    line-height: 22px;
    font-weight: $font-weight-4;
    color: $copy-color;
}

h1 {
    font-size: 36px;
    line-height: 38px;
    font-weight: $font-weight-1;
    margin: 0;
    margin-bottom: 32px;
}

h2 {
    font-size: 26px;
    line-height: 28px;
    font-weight: $font-weight-1;
    margin: 0;
    margin-bottom: 32px;
}

h3 {
    font-size: 24px;
    line-height: 25px;
    font-weight: $font-weight-2;
    margin: 0;
}

.h3 {
    font-size: 24px;
    line-height: 25px;
    font-weight: $font-weight-2;
    margin: 0;
}

h4 {
    font-size: 20px;
    line-height: 22px;
    font-weight: $font-weight-1;
    margin: 0;
}

h5 {
    font-size: 22px;
    line-height: 24px;
    font-weight: $font-weight-3;
    margin: 0;
}

.h6 {
    font-size: 18px;
    line-height: 20px;
    font-weight: $font-weight-2;
    margin: 0;
}

h6 {
    font-size: 18px;
    line-height: 20px;
    font-weight: $font-weight-2;
    margin: 0;
}

.h6-single-lesson {
    font-weight: $font-weight-2;
    color: $tertiary-color;
}

.h7 {
    font-size: 0.8em;
}

.column-bg{
    padding-left: 32px; padding-right: 32px; background-color: #F2F5FF;
    @media (min-width: $max-width-mobile) {
        height: calc(100vh - 48px); 
    }
    
}

/* Custom Button */
.primaryButton,
.primaryButton:focus {
    background-color: $primary-color;
    color: $quinary-color;
    font-size: 14px;
    line-height: 17px;
    border-radius: 2px;
    border-width: 1px;
    border-color: $primary-color;
    font-weight: $font-weight-1;
    padding: 9px 24px;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    text-shadow: none;

    &:hover {
        background-color: $tertiary-color;
        border-color: $primary-color;
        color: $quinary-color;
    }

    &:active {
        background-color: $secondary-color;
        color: $primary-color;
        border-color: $secondary-color;
    }

    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
        color: $quaternary-color;
        border-color: $quaternary-color;
        background-color: transparent;
    }

    & svg {
        margin-left: 8px;
        margin-bottom: -2px;
    }
}


.secondaryButton,
.secondaryButton:focus {
    background-color: transparent;
    color: $primary-color;
    font-size: 14px;
    line-height: 17px;
    border-radius: 2px;
    border-width: 1px;
    border-color: $primary-color;
    font-weight: $font-weight-1;
    padding: 9px 24px;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    text-shadow: none;

    &:hover {
        border-color: $tertiary-color;
        color: $tertiary-color;
        background-color: transparent;

        svg path {
            fill: $tertiary-color;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }

    &:active {
        background-color: $secondary-color;
        color: $primary-color;
        border-color: $secondary-color;
    }

    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
        color: $quaternary-color;
        border-color: $quaternary-color;
        background-color: transparent;
    }

    & svg {
        margin-left: 8px;
        margin-bottom: -2px;

        & path {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
}

.tertiaryButton,
.tertiaryButton:focus {
    background-color: transparent;
    color: $primary-color;
    font-size: 14px;
    line-height: 17px;
    border-radius: 2px;
    border-width: 0px;
    font-weight: $font-weight-3;
    padding: 0;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    text-shadow: none;

    &:hover {
        color: $tertiary-color;
        background-color: transparent;

        svg path {
            fill: $tertiary-color;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }

    &:active {
        color: $primary-color;
        font-weight: $font-weight-1;
    }

    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
        color: $tertiary-color;
        background-color: transparent;
    }

    & svg {
        margin-left: 8px;
        margin-bottom: -2px;

        path {
            fill: $primary-color;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
}

.medium-button,
.medium-button:focus {
    background-color: $secondary-color;
    border-style: solid;
    border-color: $tertiary-color;
    border-width: 0.5px;
    border-radius: 2px;
    padding: 24px;
    height: auto;
    font-size: 20px;
    line-height: 22px;
    color: $tertiary-color;
    font-weight: bold;
    text-align: left;

    &:hover {
        background-color: $tertiary-color;
        color: $quinary-color;
        border-color: $tertiary-color;
    }
}

.large-button,
.large-button:focus {
    background-color: $primary-color;
    border-style: solid;
    border-color: $primary-color;
    border-width: 0;
    border-radius: 2px;
    padding: 48px;
    height: auto;
    font-size: 24px;
    line-height: 25px;
    color: $quinary-color;
    font-weight: $font-weight-2;
    text-align: left;
    display: flex;
    align-items: center;

    &:hover {
        background-color: $quaternary-color;
        color: $primary-color;
        border-color: $quaternary-color;
    }

    & svg {

        margin-right: 36px;

        path {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }

    &:hover svg path {
        fill: $primary-color;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
}

/* ---------- */
.column-gap {
    column-gap: $column-gap;
}

.layout {
    height: 100%;
}

.layout-content-page {
    overflow: hidden;
    /*height: 100vh;*/
    background-color: $quinary-color;

    @media (max-width: $max-width-mobile) {
        overflow-y: scroll;
    }
}

.layout-content-page-content {
    background-color: $secondary-color  !important;
    height: 100vh;
    overflow: hidden;

    @media (max-width: $max-width-mobile) {
        overflow-y: scroll;
        height: auto;
    }
}

.layout-content {
    overflow: auto;
    height: 100vh;
}

/*.layout-content {
    width: $width-content;
    margin: 0 calc((100% - $width-content)/2);
    padding: 48px 0;
    min-height: 280px;

    @media (max-width: $max-width-mobile) {
        width: auto;
        margin: 20px;
        padding: 32px 0;
    }


}*/

.layout-form {
    label {
        font-size: 18px;
        line-height: 20px;
        font-weight: $font-weight-2;
        margin: 0;

        @media (max-width: $max-width-mobile) {
            font-size: 16px;
            line-height: 17px;
        }
    }

    input,
    textarea {
        padding: 12px 25px !important;
        background-color: $back-form-color;
        border-style: solid !important;
        border-width: 0.5px !important;
        border-color: $tertiary-color  !important;
        border-radius: 2px;

        &,
        &::placeholder {
            font-size: 20px;
            line-height: 24px;
            font-weight: $font-weight-4;
            color: $tertiary-color;

            @media (max-width: $max-width-mobile) {
                font-size: 15px;
                line-height: 18px;
            }
        }

    }
}

.login-form-registration a {
    color: $primary-color;
    font-weight: $font-weight-1;
    text-decoration: underline;
}

.search-filter {
    input {
        padding: 12px 25px !important;
        background-color: $back-form-color;
        border-style: solid !important;
        border-width: 0.5px !important;
        border-color: $tertiary-color  !important;
        border-radius: 2px;

        &,
        &::placeholder {
            font-size: 20px;
            line-height: 24px;
            font-weight: $font-weight-4;
            color: $tertiary-color;
        }
    }
}

.search-filter-custom {
    input {
        background-color: #f4f4f4;
    }

    .ant-input-affix-wrapper {
        background-color: #f4f4f4 !important;
    }
}

.layout-bg {
    background-color: $secondary-color;
}

.sider-menu {
    margin-top: 18px;
}

.menu-icon {
    font-size: 16pt;
    color: #fff;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
}

/*ANTD STYLE OVERRIDE*/

.ant-menu-item-selected:after, .ant-menu-item:hover:after{
    border-bottom: 2px solid $primary-color !important; 
}
.ant-btn-link {
    color: $tertiary-color;
}

.ant-layout {
    background-color: #fff;
}

.ant-menu-horizontal {
    border-bottom: 0;
}

.ant-btn-my,
.ant-btn-my:focus {
    background-color: $my-color;
    color: #000;
    border-color: #ccc;
}

#login-form {
    .ant-form-item {
        margin-bottom: 24px;
    }

    .login-form-forgot {
        text-align: right;
        font-weight: $font-weight-4;
        font-size: 14px;
        line-height: 22px;
        margin-top: -24px;
        margin-bottom: 24px;
        text-decoration: underline;

        a {
            color: $primary-color;
        }
    }
}


.ant-form-item {
    margin-bottom: 32px;

    @media (max-width: $max-width-mobile) {
        margin-bottom: 16px;
    }
}

.ant-input-affix-wrapper {
    padding: 0px !important;
    border-width: 0px !important;
    background-color: $back-form-color  !important;
    border-style: solid !important;
    border-color: $tertiary-color  !important;
    border-width: 0.5px !important;
    height: 100%;

    input {
        border: 0px !important;
    }

    svg {
        fill: $primary-color;
        margin-right: 10px;
    }
}

.ant-input-prefix {
    margin-left: 25px;
}

.ant-radio-wrapper-in-form-item {
    margin-top: 16px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    font-weight: $font-weight-4  !important;

}

.ant-select-selector {
    border-style: solid !important;
    border-color: $tertiary-color  !important;
    border-width: 0.5px !important;
    border-radius: 2px !important;
    padding: 12px 16px !important;
    background-color: $back-form-color  !important;
    font-size: 20px !important;
    font-weight: $font-weight-4  !important;
    line-height: 24px !important;
    height: auto !important;

    .ant-select-selection-placeholder {
        color: $tertiary-color;
    }
}

.ant-select-arrow svg {
    fill: $tertiary-color;
    margin-right: 10px;
}

.ant-upload.ant-upload-select {
    display: block;
}

.ant-upload button {
    border-style: solid !important;
    border-color: $tertiary-color  !important;
    border-width: 0.5px !important;
    border-radius: 2px !important;
    padding: 20px 25px !important;
    background-color: $back-form-color  !important;
    font-size: 20px !important;
    font-weight: $font-weight-4  !important;
    line-height: 24px !important;
    height: auto !important;
    color: $tertiary-color  !important;
    text-align: left;

    & svg {
        margin-right: 20px;
    }
}

// Custom Header
.layout-header {
    background-color: $secondary-color;
    padding: 0px 20px;
    height: 48px;
    margin: 0;
    box-shadow: 0px 0px 30px #26439529;
    z-index: 1000;

    #Setting:hover path,
    #Plus:hover path {
        fill: $primary-color;
    }

    .avatar-column {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 48px;

        .ant-avatar {
            background: $tertiary-color;

            &:hover {
                background: $primary-color;
            }
        }
    }
}

.header-menu {

    li,
    li:focus {
        margin: 0 !important;
        line-height: 48px !important;
        height: 48px !important;
        padding: 0 55px !important;
        width: fit-content !important;
        font-size: 18px;
        line-height: 20px;
        color: $tertiary-color;
        background-color: $secondary-color  !important;
    }

    & li:hover {
        background-color: $tertiary-color  !important;
        color: $quinary-color  !important;
    }

    & .ant-menu-item-selected,
    .ant-menu-item-selected:hover {
        background-color: $secondary-color  !important;
        color: $primary-color  !important;
        font-weight: $font-weight-1  !important;
    }
}

.secondary-header {
    background-color: $secondary-color;
    padding: 32px 0;
    height: 95px;
}

.other-courses-section {
    background-color: $secondary-color;
    padding: 32px 0;

    @media (max-width: $max-width-mobile) {
        padding: 48px 20px;
    }
}

// Custom sider

.layout-sider {
    width: 277px !important;
    max-width: 277px !important;
    min-width: 277px !important;
    background-color: $secondary-color;

    &-student {
        background-color: $quinary-color;
        width: 308px !important;
        max-width: 308px !important;
        min-width: 308px !important;
    }

}

.sider-custom {
    .ant-menu {
        background: transparent;
        padding-top: 32px;

        &-vertical {
            border-right: 0px;
        }

        &-item {
            font-size: 18px;
            line-height: 20px;
            font-weight: $font-weight-3;
            color: $copy-color;
            padding: 13px 50px;
            margin-bottom: 16px;
            height: auto;

            &:hover {
                background-color: $quaternary-color;
                color: $copy-color  !important;
            }
        }

        &-item-selected,
        &-item-selected:hover {
            font-weight: $font-weight-2;
            color: $primary-color  !important;
            background-color: transparent !important;
        }

    }
}

.sider-student {
    .ant-menu {
        background-color: transparent;
        margin-right: 24px;
        margin-left: 55px;

        &-vertical {
            border-right: 0px;
        }

        &-item {
            font-size: 18px;
            line-height: 20px;
            font-weight: $font-weight-3;
            color: $copy-color;
            padding: 4px 10px 4px 10px;
            margin-bottom: 16px;
            height: auto;

            &:hover {
                color: $copy-color  !important;
            }
        }

        &-item-selected,
        &-item-selected:hover {
            font-weight: $font-weight-2;
            color: $copy-color  !important;
            background-color: transparent !important;
            border-left: solid 5px $primary-color;
        }
    }
}

// Custom sections
.login-content {
    padding: 50px 0;

    @media (max-width: $max-width-mobile) {
        padding: 0;
    }
}

.login-left-column {
    display: flex !important;
    align-content: center !important;
    align-items: center !important;

}

.login-right-column {
    padding: 96px 111px;
    background-color: $secondary-color;
    border-radius: 2px;

    @media (max-width: $max-width-mobile) {
        padding: 72px 40px;
    }
}

.single-section-profile {
    background-color: $secondary-color;
    border-radius: 4px;
    padding: 0px 111px 36px 111px !important;
    width: calc(50% - $column-gap/2);

    @media (max-width: $max-width-mobile) {
        width: 100%;
        padding: 40px !important;
    }
}

.single-section-custom-0 {
    background-color: $secondary-color;
    border-radius: 4px;

    @media (max-width: $max-width-mobile) {
        padding: 32px 24px !important;
    }
}

.single-section-custom {
    background-color: $secondary-color;
    border-radius: 4px;
    padding: 48px !important;

    @media (max-width: $max-width-mobile) {
        padding: 32px 24px !important;
    }
}

.single-section-custom-2 {
    background-color: $secondary-color;
    border-radius: 4px;
    padding: 88px !important;
}

.content-max-width {
    //max-width: 98%;
    width: 100%;
}

.block-max-width {
    max-width: 1300px;
}

.block-middle-width {
    max-width: 650px;
}



.custom-secondary-header {
    padding: 32px 0;
    padding-left: 0px !important;
    padding-right: 14px;
    //background-color: $secondary-color;

    &-student {
        padding: 24px 0 16px 0;
        margin-left: 0 !important;
    }
}

.section-bg {
    background-color: $quinary-color;
    height: calc(100vh - 310px);
    //overflow-x: hidden;
    padding-top: 0;
    padding-bottom: 48px;
    //-ms-overflow-style: none;
    /* Hide scrollbar on IE and Edge */
    //scrollbar-width: none;
    /* Hide scrollbar on Firefox */

    &::-webkit-scrollbar {
        //display: none;
        /* Hide scrollbar on Chrome, Safari, Opera */
        /* width: 0;  /* Remove scrollbar space */
        /* background: transparent;  /* Optional: just make scrollbar invisible */
    }
}

.section-background {
    background-color: $quinary-color;
    height: calc(100vh - 168px);
    overflow-x: hidden;
    padding-top: 0;
    padding-bottom: 48px;
    -ms-overflow-style: none;
    /* Hide scrollbar on IE and Edge */
    scrollbar-width: none;
    /* Hide scrollbar on Firefox */

    &::-webkit-scrollbar {
        display: none;
        /* Hide scrollbar on Chrome, Safari, Opera */
        /* width: 0;  /* Remove scrollbar space */
        /* background: transparent;  /* Optional: just make scrollbar invisible */
    }
}

.scrolling-default {
    height: 100vh;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* Hide scrollbar on IE and Edge */
    scrollbar-width: none;
    /* Hide scrollbar on Firefox */

    &::-webkit-scrollbar {
        display: none;
        /* Hide scrollbar on Chrome, Safari, Opera */
        /* width: 0;  /* Remove scrollbar space */
        /* background: transparent;  /* Optional: just make scrollbar invisible */
    }
}

.scrolling-section-104 {
    width: 100%;
    background-color: $quinary-color;
    overflow-x: hidden;
    padding-bottom: 96px;
    height: calc(100vh - 148px);
    ;
}

.scrolling-section {
    background-color: $quinary-color;
    overflow-x: hidden;
    padding-bottom: 96px;
    height: 100vh;
    /*-ms-overflow-style: none;
    /* Hide scrollbar on IE and Edge */
    //scrollbar-width: none;
    /* Hide scrollbar on Firefox */

    //&::-webkit-scrollbar {
    //display: none;
    /* Hide scrollbar on Chrome, Safari, Opera */
    /* width: 0;  /* Remove scrollbar space */
    /* background: transparent;  /* Optional: just make scrollbar invisible */
    //}
}

.mobile-section {
    background-color: $secondary-color;
    padding: 32px 20px;
}

.my-courses-section {
    padding: 40px 20px;
}

.custom-table {
    //margin-left: 32px;
    //padding-right: 28px;
    //width: $width-content;

    table {
        border-spacing: 0px 16px;

        .ant-table-cell-row-hover {
            background-color: $secondary-color  !important;
        }

        tr {
            border-radius: 2px;
            background-color: $secondary-color;

            td {
                padding: 40px 48px;
                border-width: 0;
                margin-bottom: 16px;
            }
        }

    }

    &-student {
        margin-left: 24px;
        padding-right: 28px;
    }

}

.setting-table {
    table {
        border-spacing: 0px 16px;
    }

    .ant-table-cell-row-hover {
        background-color: $secondary-color  !important;
    }

    tr {
        border-radius: 2px;
        background-color: $secondary-color;

        td {
            padding: 13px 16px;
            border-width: 0;
            margin-bottom: 16px;
            font-weight: 600;
        }
    }

}

.overview-section {
    //background-image: linear-gradient(90deg, $secondary-color 50%, $quinary-color 50%);
    //height: 100vh;
    //padding-top: 48px;
    width: 100%;
}

.lesson-section {
    background-image: linear-gradient(90deg, $secondary-color 50%, $quinary-color 50%);
    height: 100vh;
    overflow: hidden;
}

// Custom column
.column-left-50 {
    padding-right: 111px;
    background-color: $secondary-color;
    z-index: 1;

    @media (max-width: $max-width-mobile) {
        padding-right: 0;
    }
}

.column-right-50 {
    padding-left: 28px;
    z-index: 0;
    background-color: white;

    @media (max-width: $max-width-mobile) {
        padding-left: 0;
    }
}

.column-left-75 {
    padding-right: 48px;
    background-color: $secondary-color;
    height: 100vh;
    padding-top: 32px;
    padding-bottom: 150px;
    overflow: hidden;

    @media (max-width: $max-width-mobile) {
        padding: 0;
        height: auto;
        background-color: $quinary-color;
    }
}

.column-lesson-1 {
    @media (max-width: $max-width-mobile) {
        padding: 10px 20px 16px 20px;
    }
}

.column-lesson-2 {
    @media (max-width: $max-width-mobile) {
        padding: 24px 20px 40px 20px;
        background-color: $secondary-color;
    }
}

.column-right-25 {
    padding-left: 76px;
    background-color: $quinary-color;
    height: 100vh;
    padding-top: 32px;
    padding-bottom: 150px;
    overflow: hidden;

    @media (max-width: $max-width-mobile) {
        padding: 40px 20px 60px 20px;
        height: auto;
    }
}

.custom-column-vertical-center-alignment {
    display: flex !important;
    align-items: center !important;
    align-content: center !important;
}

.custom-column-vertical-end-alignment {
    display: flex !important;
    align-items: flex-end !important;
    align-content: flex-end !important;
}

.single-course-column {
    border-radius: 2px;
    background-color: $secondary-color;
    padding: 36px;
}

.column-right-align {
    display: flex;
    justify-content: flex-end;
}

// Elements spacing
.elements-spacing {
    margin-bottom: 24px;

    &-t8 {
        margin-top: 8px;
    }

    &-t16 {
        margin-top: 16px;
    }

    &-t48 {
        margin-top: 48px;
    }

    &-b0 {
        margin-bottom: 0;
    }

    &-b16 {
        margin-bottom: 16px;
    }

    &-b32 {
        margin-bottom: 32px;
    }

    &-b48 {
        margin-bottom: 48px;
    }

    &-b96 {
        margin-bottom: 96px;
    }

}

/* Mobile Width */
@media (max-width: $max-width-mobile) {
    body {
        font-size: 15px;
        line-height: 20px;
    }

    h1 {
        font-size: 27px;
        line-height: 29px;
    }

    h2 {
        font-size: 23px;
        line-height: 24px;
    }

    h3 {
        font-size: 21px;
        line-height: 22px;
    }

    h4 {
        font-size: 17px;
        line-height: 18px;
    }

    h5 {
        font-size: 18px;
        line-height: 20px;
    }

    h6 {
        font-size: 16px;
        line-height: 17px;
    }
}

.carousel-container {
    position: relative;
    text-align: center;
    color: white;
}

.bottom-left {
    position: absolute;
    bottom: 26px;
    left: 16px;
    font-weight: $font-weight-1;
}

.cover {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
}